import axios from "axios";

class UserService {
    static BASE_URL = process.env.REACT_APP_BASE_URL;

    // Add interceptors directly to the global axios instance
    static setupInterceptors() {
        axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                // Check if the error is due to an expired token (401) and it's not a retry request
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    const refreshToken = localStorage.getItem('refreshToken');

                    if (!refreshToken) {
                        this.logout();
                        window.location.href = '/login';
                        return Promise.reject(error);
                    }

                    try {
                        console.log("Regenerate access token");
                        const { data } = await axios.post(`${UserService.BASE_URL}/auth/refresh`, { refreshToken });

                        localStorage.setItem('token', data.token);
                        originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

                        // Wiederhole die ursprüngliche Anfrage
                        return axios(originalRequest);
                    } catch (err) {
                        this.logout();
                        window.location.href = '/login';
                        return Promise.reject(err);
                    }
                }

                // Handle 500 - Internal Server Error
                if (error.response.status === 500) {
                    this.logout();
                    window.location.href = '/login';
                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        );

        /*/ Add a request interceptor to set the Authorization header automatically
        axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );*/
    }

    static async login(username, password) {
        try {
            const response = await axios.post(`${UserService.BASE_URL}/auth/login`, { username, password });

            return response.data;
        } catch (err) {
            throw err;
        }
    }

    // Methode zur Verifizierung des 2FA-Codes
    static async verify2FA(username, twoFactorCode) {
        const response = await axios.post(`${UserService.BASE_URL}/auth/verify2FACode`, {
            username: username,
            twoFactorCode: twoFactorCode
        });
        return response.data; // Token und weitere Daten vom Server
    }

    static async register(userData, token) {
        try {
            const response = await axios.post(`${UserService.BASE_URL}/auth/register`, userData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getAllUsers(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/admin/get-all-users`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }


    static async getYourProfile(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/adminuser/get-profile`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getUserById(userId, token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/admin/get-users/${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async deleteUser(userId, token) {
        try {
            const response = await axios.delete(`${UserService.BASE_URL}/admin/delete/${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }


    static async updateUser(userId, userData, token) {
        try {
            const response = await axios.put(`${UserService.BASE_URL}/admin/update/${userId}`, userData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async updateUserInfo(userData, token) {
        try {
            const response = await axios.put(`${UserService.BASE_URL}/user/update-info`, userData,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getSubjects(country, token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/subjects/${country}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    /* Homework */
    static async getUserHomework(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/homework/user`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getAllHomework(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/homework/admin`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async createHomework(homework, token) {
        console.log(token);
        try {
            const response = await axios.post(`${UserService.BASE_URL}/homework/user`, [homework], {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async updateHomework(homework, token) {
        console.log(token);
        try {
            const response = await axios.put(`${UserService.BASE_URL}/homework/user`, [homework], {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async deleteHomework(homeworkId, token) {
        try {
            const response = await axios.delete(`${UserService.BASE_URL}/homework/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: [homeworkId]  // Backend erwartet eine Liste von UUIDs
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async markHomeworkAsCompleted(homeworkId, token) {
        try {
            const response = await axios.put(`${UserService.BASE_URL}/homework/user/${homeworkId}/complete`, null, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            return response.data;
        } catch (error) {
            console.error("Error during API call:", error);
            throw error;
        }
    }

    static async markHomeworkAsNotCompleted(homeworkId, token) {
        try {
            const response = await axios.put(`${UserService.BASE_URL}/homework/user/${homeworkId}/not-complete`, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    // Funktion zum Anpinnen einer Hausaufgabe
    static async pinHomework(homeworkId, token) {
        try {
            const response = await axios.patch(`${UserService.BASE_URL}/homework/user/${homeworkId}/pin`, { pin: true }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    // Funktion zum Entfernen des Anpinnens einer Hausaufgabe
    static async unpinHomework(homeworkId, token) {
        try {
            const response = await axios.patch(`${UserService.BASE_URL}/homework/user/${homeworkId}/pin`, { pin: false }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getHomeworkById(homeworkId, token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/homework/user/${homeworkId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                })
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    // Methode, um die Health-Informationen zu erhalten
    static async getHealthInfo(token) {
        try {
            const response = await axios.get(`${UserService.BASE_URL}/health`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    // Flashcards
    static async getPublicFlashcardGroups() {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${UserService.BASE_URL}/flashcards/public`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    static async createFlashcardGroup(groupName, isPublic) {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${UserService.BASE_URL}/flashcards`, null, {
            params: {
                groupName,
                isPublic,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    }

    /**AUTHENTICATION CHECKER */
    static logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role')
    }

    static isAuthenticated() {
        const token = localStorage.getItem('token')
        return !!token
    }

    static isAdmin() {
        const role = localStorage.getItem('role')
        return role === 'ADMIN'
    }

    static isUser() {
        const role = localStorage.getItem('role')
        return role === 'USER'
    }

    static adminOnly() {
        return this.isAuthenticated() && this.isAdmin();
    }

}

UserService.setupInterceptors();

export default UserService;