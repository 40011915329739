import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../service/UserService";
import { useAuth } from './../context/AuthContext'; // Importiere den AuthContext

function LoginPage() {
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [requires2FA, setRequires2FA] = useState(false); // Status für 2FA
    const [twoFactorCode, setTwoFactorCode] = useState(''); // 2FA-Code
    const [userData, setUserData] = useState(null); // Benutzer-Daten für 2FA-Schritt
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = await UserService.login(username, password);
            console.log(data);

            if (data.requires2FA) {
                // 2FA ist erforderlich
                setRequires2FA(true);
                setUserData(data); // Speichere Benutzerdaten für den 2FA-Schritt
            } else if (data.token) {
                // Kein 2FA erforderlich, normaler Login
                completeLogin(data);
            } else {
                setError(data.message || 'Login failed');
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    }

    const handle2FASubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await UserService.verify2FA(username, twoFactorCode);
            if (response.token) {
                // Login abschließen
                completeLogin(response);
            } else {
                setError('Invalid 2FA code');
            }
        } catch (error) {
            console.log(error);
            setError('Failed to verify 2FA');
        }
    }

    const completeLogin = (data) => {
        // Store tokens and role in localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);            
        localStorage.setItem('role', data.role);
        login();
        navigate('/profile');
    }

    return (
        <div className="auth-container">
            <h2>{requires2FA ? "Enter 2FA Code" : "Login"}</h2>
            {error && <p className="error-message">{error}</p>}
            
            {!requires2FA ? (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Username: </label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Password: </label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type="submit">Login</button>
                </form>
            ) : (
                <form onSubmit={handle2FASubmit}>
                    <div className="form-group">
                        <label>2FA Code: </label>
                        <input type="text" value={twoFactorCode} onChange={(e) => setTwoFactorCode(e.target.value)} />
                    </div>
                    <button type="submit">Verify 2FA</button>
                </form>
            )}
        </div>
    )
}

export default LoginPage;
