// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation  } from "react-router-dom";
import Navbar from './components/common/Navbar';
import FooterComponent from './components/common/Footer';
import { AuthProvider, useAuth } from './components/context/AuthContext'; // Importiere AuthProvider und useAuth

import LoginPage from './components/auth/LoginPage';
import RegistrationPage from './components/auth/RegistrationPage';
import UserManagementPage from './components/userspage/pages/UserManagementPage';
import ProfilePage from './components/userspage/pages/ProfilePage';
import HomeworkPage from './components/userspage/pages/HomeworkPage';
import CardPage from './components/userspage/pages/LearnCardsPage';
import MusicXMLPage from './components/userspage/pages/MusicXMLPage';

import UpdateUser from './components/userspage/UpdateUser';
import ChangeUserInfo from './components/userspage/ChangeUserInfo';
import AddOrUpdateHomework from './components/userspage/AddOrUpdateHomework';
import Impressum from './components/userspage/Impressum';
import Datenschutz from './components/userspage/Datenschutz';

// PrivateRoute ist eine Hilfs-Komponente, um geschützte Routen zu erstellen
function PrivateRoute({ element, ...rest }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
}

function AdminRoute({ element, ...rest }) {
  const { isAuthenticated, isAdmin } = useAuth();
  return isAuthenticated && isAdmin ? element : <Navigate to="/login" />;
}

// RedirectAuthenticated ist eine Hilfs-Komponente für Umleitungen bei bereits authentifizierten Benutzern
function RedirectAuthenticated({ redirectTo }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to={redirectTo} /> : <LoginPage />;
}

// Funktion zur Anzeige der Navbar basierend auf der aktuellen Route
function ShowNavbar() {
  const location = useLocation();
  const hideNavbarOnRoutes = ["/login"];
  const shouldHideNavbar = hideNavbarOnRoutes.some(route => location.pathname.startsWith(route));
  return !shouldHideNavbar && <Navbar />;
}

// Funktion zur Anzeige des Footers basierend auf der aktuellen Route
function ShowFooter() {
  const location = useLocation();
  const hideFooterOnRoutes = ["/login"];
  const shouldHideFooter = hideFooterOnRoutes.some(route => location.pathname.startsWith(route));
  return !shouldHideFooter && <FooterComponent />;
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          {/* Navbar und Footer getrennt behandeln */}
          <ShowNavbar />
          <div className="content">
            <Routes>
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/privacy" element={<Datenschutz />} />

              {/* Wenn der Benutzer bereits authentifiziert ist, wird er zum Profil weitergeleitet */}
              <Route exact path="/" element={<RedirectAuthenticated redirectTo="/profile" />} />
              <Route exact path="/login" element={<RedirectAuthenticated redirectTo="/profile" />} />

              {/* Geschützte Routen */}
              <Route exact path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<PrivateRoute element={<ProfilePage />} />} />
              <Route path="/homework" element={<PrivateRoute element={<HomeworkPage />} />} />
              <Route path="/change-info/:userId" element={<PrivateRoute element={<ChangeUserInfo />} />} />
              <Route path="/add-homework" element={<PrivateRoute element={<AddOrUpdateHomework />} />} />
              <Route path="/edit-homework/:homeworkId" element={<PrivateRoute element={<AddOrUpdateHomework />} />} />

              {/* Admin routes */}
              <Route path="/register" element={<AdminRoute element={<RegistrationPage />} />} />
              <Route path="/admin/user-management" element={<AdminRoute element={<UserManagementPage />} />} />
              <Route path="/update-user/:userId" element={<AdminRoute element={<UpdateUser />} />} />
              
              <Route path="/card" element={<AdminRoute element={<CardPage />} />} />
              <Route path="/musicxml" element={<AdminRoute element={<MusicXMLPage />} />} />

              {/* Fallback route */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>
          <ShowFooter />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
