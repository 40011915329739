import React, { useEffect, useRef, useState } from 'react';
import JSZip from 'jszip';
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay';

const MusicXMLViewer = () => {
  const osmdContainer = useRef(null);
  const osmdInstance = useRef(null);
  const [scores, setScores] = useState([]);
  const [selectedScore, setSelectedScore] = useState('');

  useEffect(() => {
    const loadMusicMXL = async () => {
      try {
        const response = await fetch('/Alle Jahre wieder/music.musicxml'); // Pfad zur MusicXML-Datei
        const scoreXML = await response.text();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(scoreXML, "application/xml");

        const scoreElements = xmlDoc.getElementsByTagName('score-part');
        const scoreList = Array.from(scoreElements).map((part) => {
          const id = part.getAttribute('id');
          const name = part.getElementsByTagName('part-name')[0].textContent;
          return { id, name };
        });

        // Füge die Partitur zur Liste hinzu
        const fullScore = { id: 'full', name: 'Partitur' }; // Beispiel-Name für die Partitur
        setScores([fullScore, ...scoreList]); // Partitur an den Anfang der Liste setzen
        setSelectedScore(scoreList[0]?.id || 'full'); // Setze die Partitur als Standard
      } catch (error) {
        console.error('Fehler beim Laden der .musicxml-Datei:', error);
      }
    };

    loadMusicMXL();
  }, []);

  useEffect(() => {
    if (osmdContainer.current && selectedScore) {
      const loadScore = async () => {
        try {
          let musicFileName;

          if (selectedScore === 'full') {
            musicFileName = 'music.musicxml'; // Dateiname für die Partitur
          } else {
            const selectedScoreObject = scores.find(score => score.id === selectedScore);
            const formattedName = selectedScoreObject?.name
              .replace(/\s+/g, '_')
              .replace(/[äöüß]/g, (match) => {
                switch (match) {
                  case 'ä': return 'ae';
                  case 'ö': return 'oe';
                  case 'ü': return 'ue';
                  case 'ß': return 'ss';
                  default: return match;
                }
              });
            musicFileName = `music-${formattedName}.musicxml`; // Dynamischer Dateiname
          }

          const response = await fetch(`/Alle Jahre wieder/${musicFileName}`); // Angepasster Pfad
          const musicXMLData = await response.text();

          if (osmdInstance.current) {
            osmdInstance.current.load(musicXMLData).then(() => osmdInstance.current.render());
          } else {
            osmdInstance.current = new OpenSheetMusicDisplay(osmdContainer.current);
            osmdInstance.current.load(musicXMLData).then(() => osmdInstance.current.render());
          }
        } catch (error) {
          console.error('Fehler beim Laden der Score:', error);
        }
      };

      loadScore();
    }
  }, [selectedScore, scores]);

  return (
    <div>
      <h1>MusicXML Viewer</h1>
      <select onChange={(e) => setSelectedScore(e.target.value)} value={selectedScore}>
        {scores.map(score => (
          <option key={score.id} value={score.id}>{score.name}</option>
        ))}
      </select>
      <div ref={osmdContainer} style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px' }}></div>
    </div>
  );
};

export default MusicXMLViewer;
