import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserService from '../service/UserService';

function EditHomework() {
  const navigate = useNavigate();
  const { homeworkId } = useParams(); // homeworkId von der URL erhalten

  const [homeworkData, setHomeworkData] = useState({
    fachData: '',
    datum: '',
    text: '',
    id: null // ID wird nur beim Bearbeiten benötigt
  });
  const [subjects, setSubjects] = useState([]); // Array für die Fächer

  useEffect(() => {
    if (homeworkId) {
      fetchHomeworkDataById(homeworkId); // Nur bei Bearbeiten
    }
    fetchSubjects();
  }, [homeworkId]);

  const fetchHomeworkDataById = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await UserService.getHomeworkById(id, token);
      const { fachData, datum, text } = response;
      setHomeworkData({ fachData, datum, text, id }); // ID hinzufügen und Fach vorauswählen
    } catch (error) {
      console.error('Fehler beim Abrufen der Hausaufgabe:', error);
    }
  };

  const fetchSubjects = async () => {
    try {
        const token = localStorage.getItem('token');
        const country = 'germany'; // Hier könntest du das Land dynamisch setzen
        const response = await UserService.getSubjects(country, token);

        // Mapping the response to a format where keys are fachData (e.g., 'germany.ma')
        const mappedSubjects = Object.keys(response).reduce((acc, key) => {
            const subject = response[key];
            const abbreviation = subject.abbreviation;
            const name = subject.name;
            acc[`${country}.${abbreviation}`] = name; // Keep the original key format
            return acc;
        }, {});

        setSubjects(mappedSubjects);
    } catch (error) {
        console.error('Fehler beim Abrufen der Fächer:', error);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHomeworkData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');

      if (homeworkId) {
        // Bei Bearbeiten
        console.log("Bearbeiten:");
        console.log(homeworkData);
        await UserService.updateHomework(homeworkData, token);
      } else {
        // Bei Hinzufügen
        console.log("Hinzufügen:");
        console.log(homeworkData);
        await UserService.createHomework(homeworkData, token);
      }
      navigate('/homework'); // Nach erfolgreicher Bearbeitung zur Hausaufgaben-Seite navigieren
    } catch (error) {
      console.error('Fehler beim Speichern der Hausaufgabe:', error);
    }
  };

  return (
    <div className="auth-container">
      <h2>{homeworkId ? 'Bearbeite' : 'Füge'} Hausaufgabe {homeworkId ? 'bearbeiten' : 'hinzu'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Fach:</label>
          <select
            name="fachData" // Verwende fachData als name
            value={homeworkData.fachData} // Der ausgewählte Wert ist fachData
            onChange={handleInputChange}
            required
          >
            <option value="">Wähle ein Fach</option>
            {Object.entries(subjects).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Datum:</label>
          <input
            type="date"
            name="datum"
            value={homeworkData.datum}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Text:</label>
          <textarea
            name="text"
            value={homeworkData.text}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">{homeworkId ? 'Aktualisieren' : 'Erstellen'}</button>
      </form>
    </div>
  );
}

export default EditHomework;
