import React from 'react';

const Datenschutz = () => {
    return (
        <div>
            <h1>Datenschutzerklärung</h1>
            <p>Der Schutz Ihrer persönlichen Daten ist uns wichtig...</p>

            <h2>Verantwortliche Stelle</h2>
            <p>Verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist...</p>

            <h2>Welche Daten werden erfasst?</h2>
            <p>Bei der Nutzung dieser Webseite erheben wir folgende Daten...</p>

            {/* Weitere Inhalte hier */}
        </div>
    );
};

export default Datenschutz;
