import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '../../service/UserService';
import '../../../css/Homework.css';  // Importiere deine CSS-Datei
import { useConfirm } from '../../common/ConfirmDialog';  // Importiere die ConfirmDialog-Komponente
//import HomeworkDetailPopup from '../common/HomeworkDetailPopup'; // Importiere die Popup-Komponente
import '../../../css/HomeworkDetailPopup.css'; // Importiere die CSS-Datei für das Popup
import {
    FaTrash, FaPen, FaRegCheckSquare, FaRegSquare, FaBook, FaRegCalendarAlt, FaCheckDouble, FaThumbtack, FaInfo, FaEllipsisH,
    FaSortUp, FaSortDown, FaSort,
    FaTimes
} from 'react-icons/fa';


function HomeworkPage() {
    const [homeworkList, setHomeworkList] = useState([]);
    const [filteredHomeworkList, setFilteredHomeworkList] = useState([]);

    // Filterstates für jede Spalte
    const [fachFilter, setFachFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [textFilter, setTextFilter] = useState('');
    const [erledigtFilter, setErledigtFilter] = useState('Nein');  // Standard: nur nicht erledigte

    const [sortOrder, setSortOrder] = useState([{ key: 'datum', order: 'asc' }]);
    const today = new Date();
    const isAdmin = UserService.isAdmin();
    const [debugInfosShow, setShowDebugInfos] = useState(false);
    const [showGrouped, setShowGrouped] = useState(true); // Zustand für die Gruppierung

    const [subjectList, setSubjectList] = useState({});
    const [abbreviationList, setAbbreviationList] = useState({});
    const [subjectsLoaded, setSubjectsLoaded] = useState(false);

    const [confirm, ConfirmDialogs] = useConfirm();

    const [popupVisible, setPopupVisible] = useState(false); // Steuert die Sichtbarkeit des Popups
    const [selectedHomework, setSelectedHomework] = useState(null); // Speichert die ausgewählte Hausaufgabe

    const [isMobile, setIsMobile] = useState(false); // Steuert, ob der Bildschirm mobil istx

    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchSubjects(); // Fächer laden
                setSubjectsLoaded(true); // Fächer wurden geladen
            } catch (error) {
                console.error('Fehler beim Laden der Fächer:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (subjectsLoaded) {
            fetchHomework(); // Hausaufgaben nur laden, wenn Fächer geladen wurden
        }
    }, [subjectsLoaded]);

    useEffect(() => {
        if (homeworkList.length > 0) {
            applyFilter();
        }
    }, [homeworkList, fachFilter, startDateFilter, endDateFilter, textFilter, erledigtFilter, sortOrder]);

    // Überwache die Bildschirmgröße
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Definiere einen Schwellenwert für "mobil"
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initiale Überprüfung beim ersten Render

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchSubjects = async () => {
        try {
            const token = localStorage.getItem('token');
            const country = 'germany'; // Hier könntest du das Land dynamisch setzen
            const response = await UserService.getSubjects(country, token);

            // Two separate lists: one for subjects (full names) and one for abbreviations
            const subjectList = {};   // For full names (e.g., { "ma": "Mathematik" })
            const abbreviationList = {}; // For abbreviations (e.g., { "germany.ma": "ma" })

            Object.keys(response).forEach(key => {
                const subject = response[key];
                const abbreviation = subject.abbreviation;
                const name = subject.name;

                // Convert first letter to uppercase
                const abbreviationUpper = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);

                subjectList[`${country}.${abbreviation}`] = name; // Add to subject list
                abbreviationList[`${country}.${abbreviation}`] = abbreviationUpper; // Add to abbreviation list
            });

            //console.log(subjectList);
            //console.log(abbreviationList);

            // Set the lists in your state
            setSubjectList(subjectList);
            setAbbreviationList(abbreviationList);
        } catch (error) {
            console.error('Fehler beim Abrufen der Fächer:', error);
        }
    };

    const fetchHomework = async () => {
        try {
            const token = localStorage.getItem('token');
            let response;
            if (isAdmin) {
                response = await UserService.getAllHomework(token);
            } else {
                response = await UserService.getUserHomework(token);
            }

            // Verwende `mappedHomeworkList`, um die Fächer und Abkürzungen korrekt darzustellen
            const mappedHomeworkList = response.map(homework => ({
                ...homework,
                // Verwende subjectList, um den vollständigen Namen des Fachs zu erhalten
                fach: subjectList[homework.fachData] || homework.fachData,
                // Verwende abbreviationList, um die Abkürzung des Fachs zu erhalten
                abbreviation: abbreviationList[homework.fachData] || homework.fachData
            }));

            // Initiale Filter- und Sortierlogik anwenden
            setHomeworkList(mappedHomeworkList);
            applyFilter();  // Anwenden der Filter
        } catch (error) {
            console.error('Fehler beim Abrufen der Hausaufgaben:', error);
        }
    };

    const applyFilter = () => {
        const filtered = homeworkList.filter((homework) => {
            const matchesFach = homework.fach.toLowerCase().includes(fachFilter.toLowerCase());
            const matchesText = homework.text.toLowerCase().includes(textFilter.toLowerCase());
            const matchesErledigt = erledigtFilter === '' ||
                (erledigtFilter === 'Ja' && homework.erledigt) ||
                (erledigtFilter === 'Nein' && !homework.erledigt);

            const homeworkDate = new Date(homework.datum);

            const startDate = startDateFilter ? new Date(startDateFilter) : null;
            const endDate = endDateFilter ? new Date(endDateFilter) : null;

            // Überprüfen, ob das Datum im Bereich liegt
            const matchesDatum = (!startDate || homeworkDate >= startDate) &&
                (!endDate || homeworkDate <= endDate);

            return matchesFach && matchesDatum && matchesText && matchesErledigt;
        });

        // Sortierung anwenden
        const sortedList = filtered.sort((a, b) => {
            for (let { key, order } of sortOrder) {
                let compareResult = 0;

                if (key === 'datum') {
                    // Datum korrekt vergleichen, indem wir sie in Date-Objekte umwandeln
                    const dateA = new Date(a.datum);
                    const dateB = new Date(b.datum);
                    compareResult = dateA - dateB;
                } else if (key === 'fach') {
                    compareResult = a.fach.localeCompare(b.fach);
                } else if (key === 'text') {
                    compareResult = a.text.localeCompare(b.text);
                } else if (key === 'erledigt') {
                    compareResult = (a.erledigt === b.erledigt) ? 0 : a.erledigt ? 1 : -1;
                }

                // Wenn der Vergleich ein Ergebnis hat (nicht gleich), multiplizieren wir mit der Sortierreihenfolge
                if (compareResult !== 0) {
                    return order === 'asc' ? compareResult : -compareResult;
                }
            }
            return 0;
        });
        setFilteredHomeworkList(sortedList);
    };

    // Anpassen der Sortierreihenfolge und Spaltenauswahl
    const handleSort = (key) => {
        const existingSortIndex = sortOrder.findIndex(s => s.key === key);
        const newSortOrder = [...sortOrder];

        if (existingSortIndex !== -1) {
            if (newSortOrder[existingSortIndex].order === 'asc') {
                newSortOrder[existingSortIndex].order = 'desc';
            } else {
                // Sortierung zurücksetzen, wenn bereits absteigend
                newSortOrder.splice(existingSortIndex, 1);
            }
        } else {
            // Neue Spalte hinzufügen (maximal 3)
            if (newSortOrder.length === 3) {
                newSortOrder.shift(); // Älteste Sortierspalte entfernen
            }
            newSortOrder.push({ key, order: 'asc' });
        }

        setSortOrder(newSortOrder);
        applyFilter();
    };

    const getSortClass = (key) => {
        const sortEntry = sortOrder.find(s => s.key === key);
        return sortEntry ? sortEntry.order : null;
    };

    const renderSortIcon = (key) => {
        const sortOrder = getSortClass(key);
        if (sortOrder === 'asc') {
            return <FaSortUp />;
        } else if (sortOrder === 'desc') {
            return <FaSortDown />;
        } else {
            return <FaSort />;
        }
    };

    // Funktion zum Gruppieren der Hausaufgaben nach Datum
    const groupByDate = (homeworkList) => {
        return homeworkList.reduce((acc, curr) => {
            const date = curr.datum;
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(curr);
            return acc;
        }, {});
    };

    // Überprüfen, ob die Liste nach Datum sortiert ist
    const isSortedByDate = sortOrder.length > 0 && sortOrder[0].key === 'datum';

    const groupedHomework = isSortedByDate && showGrouped ? groupByDate(filteredHomeworkList) : null;


    const getRowColor = (isCompleted, dueDate) => {
        const dueDateObj = new Date(dueDate);
        today.setHours(0, 0, 0, 0); // Setze die Uhrzeit von today auf Mitternacht
        const daysDiff = Math.ceil((dueDateObj - today) / (1000 * 60 * 60 * 24) - 1);

        if (isCompleted) {
            return 'completed';
        } else if (dueDateObj < today) {
            return 'overdue';
        } else if (daysDiff === 0) { // Heute ist das Fälligkeitsdatum
            return 'today'; // Neue Kategorie für heute
        } else if (daysDiff <= 2) { // Fällig in den nächsten 3 Tagen (inkl. heute)
            return 'due-soon';
        } else {
            return 'pending';
        }
    };

    const markAsCompleted = async (homeworkId) => {
        try {
            // Prompt for confirmation before deleting the user
            const isConfirmed = await confirm('Do you want to Complite this item?');

            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            if (isConfirmed) {
                await UserService.markHomeworkAsCompleted(homeworkId, token);
                fetchHomework(); // Reload the list after marking the homework as completed
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Hausaufgabe:', error);
        }
    };

    const markAsNotCompleted = async (homeworkId) => {
        try {
            // Prompt for confirmation before deleting the user
            const isConfirmed = await confirm('Do you want to Not Complite this item?');

            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            if (isConfirmed) {
                await UserService.markHomeworkAsNotCompleted(homeworkId, token);
                fetchHomework(); // Reload the list after updating the homework status
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Hausaufgabe:', error);
        }
    };

    const deleteHomework = async (homeworkId) => {
        try {
            const isConfirmed = await confirm('Are you sure you want to delete this homework?');
            const token = localStorage.getItem('token');
            if (isConfirmed) {
                await UserService.deleteHomework(homeworkId, token);
                fetchHomework();
            }
        } catch (error) {
            console.error('Error deleting homework:', error);
        }
    };

    const handleEditClick = (homework) => {
        navigate(`/edit-homework/${homework.id}`);
    }

    const handleButtonClick = (e, homework, action) => {
        e.stopPropagation(); // Verhindert, dass der Click-Event die Zeile auslöst
        action(homework); // Ruft die entsprechende Aktion auf
    };

    // Funktion zum Kürzen des Datums und Berechnen der verbleibenden Tage
    const formatDate = (date, isCompleted, header) => {
        const options = header
            ? { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }
            : isMobile
                ? { day: 'numeric', month: 'numeric' }
                : { day: 'numeric', month: 'numeric', year: 'numeric' };

        // Datum formatieren
        const formattedDate = new Date(date).toLocaleDateString('de-DE', options);

        // Differenz in Tagen berechnen
        const today = new Date();
        const targetDate = new Date(date);
        const timeDiff = targetDate - today;
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Umrechnen in Tage

        // Kompakter Text basierend auf der Differenz erstellen
        let daysText = '';
        // Logik für mobile und Desktop-Version
        if (header) {
            if (daysDiff > 0) {
                daysText = `Noch ${daysDiff} Tage bis zur Abgabe.`;
            } else if (daysDiff < 0) {
                daysText = `Abgabe vor ${Math.abs(daysDiff)} Tagen.`;
            } else {
                daysText = `Abgabe ist Heute`;
            }
        } else if (isMobile) {
            if (daysDiff > 0) {
                daysText = `+${daysDiff} T`;
            } else if (daysDiff < 0) {
                daysText = `-${Math.abs(daysDiff)} T`;
            } else {
                daysText = `(Heute)`;
            }
        } else {
            if (daysDiff > 0) {
                daysText = `(${daysDiff} Tage übrig)`;
            } else if (daysDiff < 0) {
                daysText = `(${Math.abs(daysDiff)} Tage her)`;
            } else {
                daysText = `(Heute)`;
            }
        }

        // Logik für mobile und Desktop-Version
        if (header) {
            return `${formattedDate} (${daysText})`;
        } else if (isMobile) {
            // In der mobilen Version nur die Tage anzeigen
            return (
                <>
                    {formattedDate}
                    <br />
                    {daysText}
                </>
            );
        } else {
            // In der Desktop-Version flexibel entweder Datum, Tage oder beides anzeigen
            if (!isCompleted) {
                return (
                    <>
                        {formattedDate}
                        <br />
                        {daysText}
                    </>
                );
            } else {
                return formattedDate;
            }
        }
    };

    const handlePin = async (homeworkId) => {
        try {
            // Bestätigung, bevor die Hausaufgabe angepinnt wird
            const isConfirmed = await confirm('Möchten Sie diese Hausaufgabe anpinnen?');

            const token = localStorage.getItem('token'); // Token aus localStorage abrufen
            if (isConfirmed) {
                await UserService.pinHomework(homeworkId, token); // Hausaufgabe anpinnen
                fetchHomework(); // Liste nach der Aktualisierung neu laden
            }
        } catch (error) {
            console.error('Fehler beim Anpinnen der Hausaufgabe:', error);
        }
    };

    const handleUnpin = async (homeworkId) => {
        try {
            // Bestätigung, bevor das Anpinnen der Hausaufgabe aufgehoben wird
            const isConfirmed = await confirm('Möchten Sie das Anpinnen dieser Hausaufgabe aufheben?');

            const token = localStorage.getItem('token'); // Token aus localStorage abrufen
            if (isConfirmed) {
                await UserService.unpinHomework(homeworkId, token); // Anpinnen der Hausaufgabe aufheben
                fetchHomework(); // Liste nach der Aktualisierung neu laden
            }
        } catch (error) {
            console.error('Fehler beim Aufheben des Anpinnens der Hausaufgabe:', error);
        }
    };

    // Öffne Popup mit den Details der ausgewählten Hausaufgabe
    const openPopup = (homework) => {
        if (true) { // Popup nur öffnen, wenn der Bildschirm mobil ist
            console.log("openPopup");
            setSelectedHomework(homework);
            setPopupVisible(true);
        }
    };

    // Schließe das Popup
    const closePopup = () => {
        console.log("closePopup");
        setSelectedHomework(null);
        setPopupVisible(false);
    };

    const resetDateFilter = () => {
        // Alle Filterzustände zurücksetzen
        setStartDateFilter('');
        setEndDateFilter('');
    };

    const handleGroupedCheckboxChange = () => {
        setShowGrouped(prevState => !prevState); // Zustand für Gruppierung umschalten
    };

    const handleDebugCheckboxChange = () => {
        setShowDebugInfos(prevState => !prevState); // Zustand für Debug-Infos umschalten
    };

    //HTML
    const renderHomeworkRow = (homework) => (
        <tr key={homework.id} onClick={isMobile ? () => openPopup(homework) : undefined}>
            <td className={getRowColor(homework.erledigt, homework.datum)}>{isMobile ? homework.abbreviation : homework.fach}</td>
            <td>{formatDate(homework.datum, homework.erledigt)}</td>
            <td>{homework.text}</td>
            <td>
                {!homework.erledigt ? (
                    <button className='complete-button' onClick={(e) => handleButtonClick(e, homework.id, markAsCompleted)}>
                        <FaRegSquare className="icon square-icon" />
                    </button>
                ) : (
                    <button className='not-complete-button' onClick={(e) => handleButtonClick(e, homework.id, markAsNotCompleted)}>
                        <FaRegCheckSquare className="icon check-square-icon" />
                    </button>
                )}
            </td>
            {debugInfosShow && <td className="hide-on-mobile">{homework.id}</td>}
            {debugInfosShow && <td className="hide-on-mobile">{homework.timestamp}</td>}
            {isAdmin && <td className="hide-on-mobile">{homework.user_id.id}</td>}
            <td className="action hide-on-mobile">
                <button className='delete-button' onClick={() => deleteHomework(homework.id)}>
                    <FaTrash className="icon delete-icon" />
                </button>
                <button onClick={() => handleEditClick(homework)}>
                    <FaPen className="icon edit-icon" />
                </button>
                {
                    homework.pin ? (
                        <button className='delete-button' onClick={() => handleUnpin(homework.id)}>
                            <FaTimes className="icon delete-icon" /> {/* Symbol für das Entfernen */}
                        </button>
                    ) : (
                        <button onClick={() => handlePin(homework.id)}>
                            <FaThumbtack className="icon pin-icon" /> {/* Symbol für das Anpinnen */}
                        </button>
                    )
                }
                <button onClick={() => openPopup(homework)}>
                    <FaInfo className="icon info-icon" />
                </button>
            </td>
        </tr>
    );

    return (
        <div className={`homework-container ${isAdmin ? 'admin-view' : 'user-view'}`}>
            <h2>Homework Page</h2>
            <button className='reg-button'><Link to="/add-homework">Add Homework</Link></button>

            {/* Filter Inputs für jede Spalte */}
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Filter by Fach"
                    value={fachFilter}
                    onChange={(e) => setFachFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filter by Text"
                    value={textFilter}
                    onChange={(e) => setTextFilter(e.target.value)}
                />
                <select
                    value={erledigtFilter}
                    onChange={(e) => setErledigtFilter(e.target.value)}
                >
                    <option value="">Alle</option>
                    <option value="Ja">Ja</option>
                    <option value="Nein">Nein</option>
                </select>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div>
                        <label>Startdatum:</label>
                        <input
                            type="date"
                            value={startDateFilter}
                            onChange={(e) => setStartDateFilter(e.target.value)}
                        />
                    </div>

                    <div>
                        <label>Enddatum:</label>
                        <input
                            type="date"
                            value={endDateFilter}
                            onChange={(e) => setEndDateFilter(e.target.value)}
                        />
                    </div>

                    {/* Button zum Zurücksetzen der Filter */}
                    <button onClick={resetDateFilter} style={{ width: 'auto' }}>Filter zurücksetzen</button>
                </div>
                <label>
                    <input type="checkbox" checked={showGrouped} onChange={handleGroupedCheckboxChange} />
                    Nach Datum gruppieren
                </label>
                {isAdmin && <label>
                    <input
                        type="checkbox"
                        checked={debugInfosShow}
                        onChange={handleDebugCheckboxChange}
                    />
                    {setShowDebugInfos ? ' Debug Infos aktiv' : ' Debug Infos inaktiv'}
                </label>}

            </div>

            <table>
                <thead>
                    <tr>
                        <th className={`sortable-header ${getSortClass('fach')}`} onClick={() => handleSort('fach')}>
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaBook className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Fach'
                            )}
                            {renderSortIcon('fach')}
                            {/*<span className={`sort-arrow ${getSortClass('fach')}`}></span> */}
                        </th>
                        <th className={`sortable-header ${getSortClass('datum')}`} onClick={() => handleSort('datum')}>
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaRegCalendarAlt className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Datum'
                            )}
                            {renderSortIcon('datum')}
                            {/*<span className={`sort-arrow ${getSortClass('datum')}`}></span>*/}
                        </th>
                        <th className={`sortable-header ${getSortClass('text')}`} onClick={() => handleSort('text')}>
                            Text
                            {renderSortIcon('text')}
                            {/*<span className={`sort-arrow ${getSortClass('text')}`}></span>*/}
                        </th>
                        <th className={`sortable-header ${getSortClass('erledigt')} `} onClick={() => handleSort('erledigt')}>
                            {true ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaCheckDouble className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Status'
                            )}
                            {renderSortIcon('erledigt')}
                            {/*<span className={`sort-arrow ${getSortClass('erledigt')}`}></span>*/}
                        </th>
                        {debugInfosShow && <th className="hide-on-mobile" onClick={() => handleSort('id')}>Id</th>}
                        {debugInfosShow && <th className="hide-on-mobile" onClick={() => handleSort('timestamp')}>Timestamp</th>}
                        {isAdmin && <th className="hide-on-mobile" onClick={() => handleSort('user_id.id')}>User</th>}
                        <th className="hide-on-mobile" >
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaEllipsisH className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Actions'
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredHomeworkList.some(homework => homework.pin) && (
                        <>
                            <tr>
                                <td colSpan="8" style={{ borderTop: '3px solid black', padding: '10px 0' }}>
                                    <strong>Angepint</strong>
                                </td>
                            </tr>
                            {filteredHomeworkList
                                .filter(homework => homework.pin) // nur angepinnte Hausaufgaben filtern
                                .map(renderHomeworkRow) // render die angepinnten Hausaufgaben
                            }
                            {!groupedHomework && (
                                <tr>
                                    <td colSpan="8" style={{ borderTop: '3px solid black', padding: '10px 0' }}></td>
                                </tr>
                            )}
                        </>
                    )}

                    {isSortedByDate && groupedHomework ? (
                        Object.keys(groupedHomework).map(date => (
                            <React.Fragment key={date}>
                                <tr>
                                    <td colSpan="8" style={{ borderTop: '3px solid black', padding: '10px 0' }}>
                                        <strong>{formatDate(date, date.erledigt, true)}</strong>
                                    </td>
                                </tr>
                                {groupedHomework[date].map(renderHomeworkRow)}
                            </React.Fragment>
                        ))
                    ) : (
                        filteredHomeworkList.map(renderHomeworkRow)
                    )}
                </tbody>
            </table>

            {/* Popup für Hausaufgabendetails */}
            {popupVisible && selectedHomework && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Details der Hausaufgabe</h3>
                        <p><strong>Fach:</strong> {selectedHomework.fach}</p>
                        <p><strong>Datum:</strong> {new Date(selectedHomework.datum).toLocaleDateString('de-DE')}</p>
                        <p><strong>Text:</strong> {selectedHomework.text}</p>
                        {isAdmin && <p><strong>Id:</strong> {selectedHomework.id}</p>}
                        {isAdmin && <p><strong>Timestamp:</strong> {selectedHomework.timestamp}</p>}
                        {isAdmin && <p><strong>User:</strong> {selectedHomework.user_id.id}</p>}
                        <p><strong>Erledigt:</strong> {selectedHomework.erledigt ? 'Ja' : 'Nein'}</p>
                        {!selectedHomework.erledigt && (
                            <button className='complete-button' onClick={() => markAsCompleted(selectedHomework.id)}>Erledigt</button>
                        )}
                        {selectedHomework.erledigt && (
                            <button className='not-complete-button' onClick={() => markAsNotCompleted(selectedHomework.id)}>Nicht Erledigt</button>
                        )}
                        <button className='delete-button' onClick={() => deleteHomework(selectedHomework.id)}>Delete</button>
                        <button><Link to={`/edit-homework/${selectedHomework.id}`}>Edit</Link></button>
                        {
                            selectedHomework.pin ? (
                                <button className='delete-button' onClick={() => handleUnpin(selectedHomework.id)}>
                                    <FaTimes className="icon delete-icon" /> {/* Symbol für das Entfernen */}
                                </button>
                            ) : (
                                <button onClick={() => handlePin(selectedHomework.id)}>
                                    <FaThumbtack className="icon pin-icon" /> {/* Symbol für das Anpinnen */}
                                </button>
                            )
                        }
                        <button onClick={closePopup}>Schließen</button>
                    </div>
                </div>
            )}

            {/* ConfirmDialog Komponente */}
            {ConfirmDialogs}
        </div>
    );
}

export default HomeworkPage;
