import React, { useState, useEffect } from 'react';
import UserService from '../../service/UserService';
import { Link } from 'react-router-dom';
import HealthCheck from '../../common/HealthCheck';


function ProfilePage() {
    const token = localStorage.getItem('token'); // Beispiel für Token aus dem Local Storage
    const [profileInfo, setProfileInfo] = useState({});

    useEffect(() => {
        fetchProfileInfo();
    }, []);

    const fetchProfileInfo = async () => {
        try {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            const response = await UserService.getYourProfile(token);
            setProfileInfo(response.ourUsers);
        } catch (error) {
            console.error('Error fetching profile information:', error);
        }
    };

    return (
        <div>
            <div className="profile-page-container">
                <h2>Profile Information</h2>
                <p>Username: {profileInfo.username}</p>
                <p>Vorname: {profileInfo.firstName}</p>
                <p>Nachname: {profileInfo.lastName}</p>
                <p>Email: {profileInfo.email}</p>
                <p>City: {profileInfo.city}</p>
                {profileInfo.role === "ADMIN" && (
                    <button><Link to={`/update-user/${profileInfo.id}`}>Update This Profile</Link></button>
                )}
                {profileInfo.role === "USER" && (
                    <button><Link to={`/change-info/${profileInfo.id}`}>Change Profile Infos</Link></button>
                )}
            </div>

            <div className="profile-page-container">
                <HealthCheck token={token} />
            </div>
        </div>
    );
}

export default ProfilePage;
