import React from 'react';

const Impressum = () => {
    return (
        <div>
            <h1>Impressum</h1>
            <p>Angaben gemäß § 5 TMG</p>
            <p>Dein Name<br />
               Deine Straße<br />
               12345 Deine Stadt</p>

            <h2>Kontakt</h2>
            <p>Telefon: -<br />
               E-Mail: info@deinewebsite.de</p>

            <h2>Haftung für Inhalte</h2>
            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt...</p>

            {/* Weitere Inhalte hier */}
        </div>
    );
};

export default Impressum;
