import React, { useState } from 'react';
import Card from '../Card'; // Pfad anpassen
import '../../../css/LearnCardsPage.css'; // Pfad anpassen
import FlashcardGroupComponent from '../FlashcardGroupComponent';

const LearnCardsPage = () => {
    // Beispiel für Gruppen und Karten
    const groups = [
        {
            groupName: "React Grundlagen",
            cards: [
                {
                    question: "Was ist React?",
                    answer: "React ist eine JavaScript-Bibliothek für den Aufbau von Benutzeroberflächen."
                },
                {
                    question: "Was sind Props?",
                    answer: "Props sind Eingabewerte, die an Komponenten übergeben werden."
                }
            ]
        },
        {
            groupName: "JavaScript Basics",
            cards: [
                {
                    question: "Was ist eine Variable?",
                    answer: "Eine Variable ist ein benannter Speicherplatz für einen Wert."
                },
                {
                    question: "Was ist ein Array?",
                    answer: "Ein Array ist eine Liste von Werten."
                }
            ]
        }
    ];

    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [resetCard, setResetCard] = useState(false);

    const currentGroup = groups[currentGroupIndex];
    const currentCard = currentGroup.cards[currentCardIndex];

    const handleNextCard = () => {
        setResetCard(true); // Karte zurücksetzen
        setTimeout(() => {
            if (currentCardIndex < currentGroup.cards.length - 1) {
                setCurrentCardIndex(currentCardIndex + 1);
            } else if (currentGroupIndex < groups.length - 1) {
                setCurrentGroupIndex(currentGroupIndex + 1);
                setCurrentCardIndex(0);
            }
            setResetCard(false); // Reset zurücksetzen
        }, 100); // Kurze Verzögerung für das Zurücksetzen
    };

    const handlePrevCard = () => {
        setResetCard(true); // Karte zurücksetzen
        setTimeout(() => {
            if (currentCardIndex > 0) {
                setCurrentCardIndex(currentCardIndex - 1);
            } else if (currentGroupIndex > 0) {
                setCurrentGroupIndex(currentGroupIndex - 1);
                setCurrentCardIndex(groups[currentGroupIndex - 1].cards.length - 1);
            }
            setResetCard(false); // Reset zurücksetzen
        }, 100); // Kurze Verzögerung für das Zurücksetzen
    };

    // Berechne, ob die Buttons deaktiviert sein sollten
    const isPrevDisabled = currentGroupIndex === 0 && currentCardIndex === 0;
    const isNextDisabled =
        (currentGroupIndex === groups.length - 1 && currentCardIndex === currentGroup.cards.length - 1);

    return (
        <div className="learn-cards-page">
            <h1>Lernkarten</h1>
            <h2>{currentGroup.groupName}</h2>
            <Card question={currentCard.question} answer={currentCard.answer} reset={resetCard} />

            <div className="navigation-buttons">
                <button onClick={handlePrevCard} disabled={isPrevDisabled}>
                    Vorherige Karte
                </button>
                <button onClick={handleNextCard} disabled={isNextDisabled}>
                    Nächste Karte
                </button>
            </div>
            {/*< div >
            <h1>Flashcard Manager</h1>
            <FlashcardGroupComponent />
        </div >*/}
        </div>
    );
};

export default LearnCardsPage;
