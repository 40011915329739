// src/components/common/FooterComponent.js
import React from 'react';
import '../../css/NavbarFooter.css'; // Achte darauf, dass der Pfad korrekt ist

const FooterComponent = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <span className="footer-text">Train-Crafter | All Rights Reserved &copy; {new Date().getFullYear()}</span>
                <div className="footer-links">
                    <a href="/impressum" className="footer-link disabled">Impressum</a>
                    <a href="/privacy" className="footer-link disabled">Datenschutz</a>
                    <p className='footer-infotext'>Impressum und Datenschutz Folgen in Kürze</p>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
