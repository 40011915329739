import React, { useState, useEffect } from 'react';
import '../../css/Card.css'; // Pfad anpassen

const Card = ({ question, answer, reset }) => {
    const [flipped, setFlipped] = useState(false);

    useEffect(() => {
        if (reset) {
            setFlipped(false); // Setze die Karte immer auf die Vorderseite zurück
        }
    }, [reset]);

    const handleClick = () => {
            setFlipped(!flipped);
    };

    return (
        <div className="card-container" onClick={handleClick}>
            <div className={`card ${flipped ? 'flipped' : ''}`}>
                <div className='card-inner'>
                    <div className="card-front">
                        <div className="card-title">Frage</div>
                        <div className="card-content">
                            <div>{question}</div>
                        </div>
                    </div>
                    <div className="card-back">
                        <div className="card-title">Antwort</div>
                        <div className="card-content">
                            <div>{answer}</div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    );
};

export default Card;
