// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import UserService from '../service/UserService'; // Importiere UserService

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Prüfe beim Laden der App, ob der Benutzer authentifiziert ist
    const checkAuth = async () => {
      const authStatus = await UserService.isAuthenticated();
      const adminStatus = await UserService.isAdmin();
      setIsAuthenticated(authStatus);
      setIsAdmin(adminStatus);
    };
    checkAuth();
  }, []);

  const login = async () => {
      setIsAuthenticated(true);
      setIsAdmin(await UserService.isAdmin());
  };

  const logout = async () => {
    await UserService.logout();
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
