import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserService from '../service/UserService';

import { useConfirm } from '../common/ConfirmDialog';  // Importiere die ConfirmDialog-Komponente

function UpdateUser() {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [confirm, ConfirmDialogs] = useConfirm();

  const [userData, setUserData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    fetchUserDataById(userId);
  }, [userId]);

  const fetchUserDataById = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await UserService.getYourProfile(token);
      const { email } = response.ourUsers;
      setUserData({ email, password: '', confirmPassword: '' });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (userData.password !== userData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const isConfirmed = await confirm('Are you sure you want to update this user?');
      if (isConfirmed) {
        const token = localStorage.getItem('token');
        const res = await UserService.updateUserInfo(userData, token);
        console.log(res);
        navigate("/profile");
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      alert('An error occurred while updating user profile');
    }
  };

  return (
    <div className="auth-container">
      <h2>Update User</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" name="email" value={userData.email} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label>New Password:</label>
          <input type="password" name="password" value={userData.password} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label>Confirm New Password:</label>
          <input type="password" name="confirmPassword" value={userData.confirmPassword} onChange={handleInputChange} />
        </div>
        <button type="submit">Update</button>
      </form>
      {ConfirmDialogs}
    </div>
  );
}

export default UpdateUser;
